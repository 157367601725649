.desktop-nav__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  row-gap: 0.5rem;
  position: relative;
}

.nav-svg {
  width: 25px;
  height: 25px;

  position: relative;
  transition: all 0.3s ease;
}

.nav-item__title {
  margin-top: 10px;
}

.mobile-nav__item {
  margin-top: 10px;
}
