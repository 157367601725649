.active {
  font-weight: 700;
}

.adminka__link-item {
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.adminka__link-item:hover {
  background-color: #d1d7e9;
  border-radius: 5px;
  transition: ease-out 0.4s;
}
