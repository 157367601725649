@import '../../styles/variables.scss';

.desktop-nav__item:hover {
  &::before {
    transform: scale(1);
    opacity: 1;
  }

  & > .nav-item__title {
    color: $green;
  }
}

.nav-item__title {
  transition: all 0.3s ease;
}

.desktop-nav__item::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 23%;

  transition: all 0.3s ease;
  opacity: 0;
  transform: scale(0);

  display: block;
  width: 40px;
  height: 40px;
  clear: both;
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 50%;
  background: $green;
}

.internet-svg::before {
  left: 33px;
  top: 12px;
  width: 44px;
  height: 44px;
}
