@import '../../styles/variables.scss';

.tariff__list-item-text::before {
  content: '';
  display: block;
  clear: both;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: $blue;
  position: absolute;
  left: -15px;
  top: 8px;
}
