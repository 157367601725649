@import '../../styles//variables.scss';

.date-container {
  position: relative;
  margin-bottom: 5px;
  width: 320px;
}

.date-picker {
  width: 320px;
  box-sizing: border-box;
  padding: 20px;
  border: none;
  outline: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}

.date-picker:focus {
  outline: 2px solid rgba(10, 40, 100, 1);
}

.date-picker.error {
  outline: 1px solid $red;
  color: $red;
  &::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
  }
}

.open-button {
  position: absolute;
  top: 50px;
  right: 5%;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}
