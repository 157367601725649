.main-navigation {
  .enable-btn {
    padding: 12px 30px;
    margin-left: 1rem;
  }

  .enable-tablet-btn {
    padding: 8px;
    margin-left: 0.5rem;
    font-size: 12px;
  }
}
