@import '../../styles/variables.scss';

.header-wrapper {
  position: relative;
}

.mobile-menu {
  position: absolute;
  z-index: 100;
  opacity: 0;
  background: $white-primary;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 3% 30px;
  transition: opacity 0.5s linear;
  cursor: none;
  pointer-events: none;
}

.mobile-menu-open.mobile-menu {
  opacity: 1;
  cursor: auto;
  pointer-events: auto;
}

.hamburger-menu__icons-wrapper {
  .user-svg,
  .phone-svg {
    width: 25px;
    height: 25px;
  }

  .phone-svg {
    margin-right: 1rem;
  }

  .user-svg,
  .phone-svg {
    cursor: pointer;
    transition: 0.3s ease-in;
  }
}

.mobile-menu {
  .phone-svg {
    width: 35px;
    height: 35px;
    margin-right: 2rem;
    border-radius: 100%;
    padding: 5px;
    border: 2px rgb(158, 158, 158) solid;
  }
}

.mobile-nav__item:hover {
  cursor: pointer;
  .nav-item__title {
    color: $green;
  }
}

.mobile-nav__item {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: 1px solid #dbdee3;
  .nav-svg {
    width: 35px;
    height: 35px;
    margin-right: 2rem;
  }
  .nav-item__title {
    margin: 0;
    transition: color 0.25s linear;
    cursor: pointer;
  }
}
