@import '../../styles/variables.scss';
.swiper-button-prev {
  left: 12px;
}

.swiper-button-next {
  left: 60px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  color: white;
  text-rendering: auto;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: $green;
  transition: color 0.2s linear;
}
