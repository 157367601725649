h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  font-size: 100%;
  font-weight: normal;
}

a.text:active, /* активная/посещенная ссылка */
a.text:hover,  /* при наведении */
a.text, a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: normal;
}

em {
  font-weight: normal;
}

a {
  text-decoration: none;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
  padding: 0;
}

/* Упрощаем работу с изображениями */
img {
  width: 100%;
  height: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

[role='button'],
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* Reset `button` and button-style `input` default styles */
input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Make `a` like a button */
[role='button'] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
