@import '../../styles/variables.scss';

.login-svg {
  fill: #ffffff;
  width: 20px;
  height: 20px;
}

.header {
  background-color: $black;
  z-index: 100;
  padding: 1rem 0;
}

.header {
  .user-svg,
  .phone-svg {
    width: 20px;
    height: 20px;
  }
}

.overflow {
  position: fixed;
  background-color: $black;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  cursor: none;
  pointer-events: none;
}

.overflow-open.overflow {
  opacity: 0.4;
  cursor: pointer;
  pointer-events: auto;
}

html {
  overflow-y: scroll;
  overflow-y: overlay;
}

.no-scroll {
  overflow-y: hidden;
}
