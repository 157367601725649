@import '../../styles/variables.scss';

.slide-addition {
  transition: 0.5s ease;
}

.arrow-svg {
  width: 50px;
  height: 50px;
}

.arrow-svg {
  transition: 0.5s ease;
  fill: $white-primary;
}

.slide-addition:hover {
  color: $green;
  .arrow-svg {
    fill: $green;
  }
}
