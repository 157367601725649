.tariff-box {
  width: 300px;
  padding: 0.5rem 0;
}

@media screen and (max-width: 1100px) {
  .tariff-box {
    width: 250px;
  }
}

@media screen and (max-width: 650px) {
  .tariff-box {
    width: 300px;
  }
}
