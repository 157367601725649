@import '../../styles/variables.scss';

.nav-toggle {
  width: 60px;
  height: 75px;
  background-color: $green;
  position: relative;
  cursor: pointer;
}

.hamburger {
  display: block;
  position: absolute;
}

.hamburger,
.hamburger::after,
.hamburger::before {
  width: 2rem;
  height: 3px;
  right: 14px;
  background-color: $white-primary;
  transition: transform 0.25s ease-out, opacity 0.2s linear;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
}

.hamburger::before {
  bottom: 8px;
}

.hamburger::after {
  top: 8px;
}

.hamburger-open .hamburger {
  transform: rotate(45deg);
}

.hamburger-open .hamburger::after {
  transform: rotate(-90deg) translate(8px);
}

.hamburger-open .hamburger::before {
  opacity: 0;
}
