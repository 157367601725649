@import '../../styles/variables.scss';

.main-navigation {
  background-color: $white-primary;
}

.desktop-nav {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
