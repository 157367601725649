@import '../../styles/variables.scss';

@media screen and (min-width: 800px) {
  .aboute-us__feature {
    flex-basis: 29%;
  }
  .feature-icon-wrap {
    max-width: 100px;
  }
}

.feature-icon-wrap {
  margin-bottom: 1rem;
}

.aboute-us__feature {
  max-width: 500px;
}

.feature-svg {
  width: 80px;
  height: 110px;
  fill: $blue-fro-icons;
}

.rocket-svg {
  stroke: $blue-fro-icons;
  height: 110px;
}
