@import '../styles/variables.scss';

::-webkit-scrollbar {
  display: none;
}

.container {
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 22px;
}

body {
  background-color: $white-primary;
}

// to make footer on the bottom of the page
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.main {
  flex: 1;
}
